<div class="page-header">
	<h3 class="page-title">
		Plinko Game Details
	</h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="col-md-12 grid-margin">
					<div class="card">
						<div class="card-body" >
							<form class="forms-sample" #forms="ngForm" autocomplete="off">

			                  	<div>
			                     	<div class="my-2 font-siz">
			                     		<label for="usrname" class="col-3">User name :</label>
			                     		<span>{{Det_name}}</span>
			                     	</div>
			                  	</div>
								<div class="my-2 font-siz">
									<label for="id" class="col-3">Game Id :</label>
			                     	<span>{{Det_id}}</span>
								</div>

								<div class="my-2 font-siz">
									<label for="betamt" class="col-3">Bet Amount :</label>
			                    	<span>{{Det_betamount}}</span>

								</div>

								<div class="my-2 font-siz">
									<label for="betamt" class="col-3">Winning Amount :</label>
			                    	<span>{{Det_betamount}}</span>

								</div>

								<div class="my-2 font-siz">
									<label for="apyout" class="col-3">Payout :</label>
			                    	<span>{{Det_payout}}</span>
								</div>


								<div class="my-2 font-siz">
									<label for="cliseed" class="col-3">Status :</label>
									<span>{{Det_status}}</span>
								</div>
								
							</form>
							<hr>
					      <div>
					        <!-- <button type="submit" class="btn btn-gradient-primary">Cancel</button> -->
					      </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
