<div class="page-header">
  	<h3 class="page-title">
	    <span class="page-title-icon bg-gradient-info text-white mr-2"><i class="mdi mdi-handshake-outline"></i>      </span>
    	User Balance
  	</h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body" *ngIf="isLoading">
				<form class="forms-sample" #profitForm="ngForm" autocomplete="off">
					<div class="d-flex">
			          	<div class="col-md-3">
			             	<div>
			             		<label for="curr">Currency</label>
			                	<select class="form-control" name="curr" required [(ngModel)]="selectCurr.currency" #currs="ngModel"(click)="getcurr($event.target.value)" >
			                		<option value="">Select Currency</option>
			                		<option *ngFor="let curr of wallbal">
									   <span>{{curr.currency}}</span>
									</option>
			                	</select>
			             	</div>
			          	</div>
			          	<div class="col-md-3">
			             	<div>
			             		<label for="currbal">User Balance</label>
			             		<div class="form-control pt-2">{{selectCurr.amount}}</div>
			             	</div>
			          	</div>
			          	<div class="col-md-3">
			             	<div>
			             		<label for="curradd">Add balance</label>
			                	<input class="form-control"  type="text" name="addbal" placeholder="Add balance" [(ngModel)]="selectCurr.addAmt" #currs="ngModel" ngModel onkeypress="return (event.charCode == 8 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57" pattern="^\d{0,50}(\.\d{1,8})?$" required>
			             	</div>
			          	</div>
			          	<div class="col-md-3">
			             	<div class="mt-4">
			             		<button class="btn-sm btn-success" (click)="submitForm(profitForm)">Add Amount</button>
			             	</div>
			          	</div>
			        </div>

			        <div>
			        </div>
			    </form>
			</div>
		</div>
	</div>
</div>
<button type="submit" class="btn btn-gradient-info" [routerLink]="['/usersInfo', userId]">Back</button>