<div class="page-header">
  <h3 class="page-title">
    <span class="page-title-icon bg-gradient-info text-white mr-2">
      <i class="mdi mdi-bank-transfer-out"></i>      
    </span>
    Withdraw
  </h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-hover" *ngIf="isLoading">
						<thead>
							<tr>
								<th>S.No</th>
	               	<th>Address</th>
	               	<th>Currency</th>
	               	<th>Amount</th>
	               	<th>Transaction ID</th>
	               	<th>status</th>
	                <th>Data & Time</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody *ngIf="withdraws.length > 0">
							<tr *ngFor="let withdraw of withdraws; let i = index">
								<td>{{i+(p*limit)+1}}</td>
								<td class="cursor" title="{{withdraw['address_info']}}" [routerLink]="['/usersInfo', withdraw.address_info]">{{ withdraw['address_info'].substr(0, 6)+"..."+withdraw['address_info'].slice(-6) }}</td>
								<td>{{withdraw.currency}}</td>
								<td>{{withdraw.amount}}</td>
								<td class="cursor" title="{{withdraw['reference_no']}}" (click)="copyText(withdraw['reference_no'], 'Transaction hash')">{{ withdraw['reference_no'].substr(0, 6)+"..."+withdraw['reference_no'].slice(-6) }}</td>
								<td class="text-capitalize">{{withdraw.status}}</td>
								<td>{{withdraw.created_at | date:'dd-MM-yyyy hh:mm:ss' }}</td>
								<td [routerLink]="['/withdraw', withdraw.CIdKey]"><span class="mdi mdi-eye"></span></td>
							</tr>
						</tbody>
						<tbody *ngIf="withdraws.length == 0">
							<tr>
								<p>No records found</p>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="paginator d-flex flex-row">
					<button color="primary" (click)="p > 0?paginate('first'):null" [disabled]="p == 0"> << </button>
					<button (click)="p > 0?paginate('prev'):null" [disabled]="p == 0"> < </button>
					<button (click)="page > p?paginate('next'):null" [disabled]="page == p"> > </button>
					<button (click)="page > p?paginate('last'):null" [disabled]="page == p"> >> </button>
				</div>
			</div>
		</div>
	</div>
</div>