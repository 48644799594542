<div class="page-header">
  <h3 class="page-title">
    <span class="page-title-icon bg-gradient-info text-white mr-2">
      <i class="mdi mdi-handshake"></i>      
    </span>
    	Support List
  </h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-hover" *ngIf="isLoading">
						<thead>
							<tr>
								<th>S.No</th>
		               	<th>Name</th>
		               	<th>Email</th>
		               	<th>Subject</th>
		               	<th>created_at</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody *ngIf="supportdata.length > 0">
							<tr *ngFor="let support of supportdata; let i = index">
								<td>{{i+(p*10)+1}}</td>
								<td>{{support.userName}}</td>
								<td>{{support.email}}</td>
								<td>{{support.SelectedOption}}</td>
								<td>{{support.created_at | date:'dd-MM-yyyy hh:mm:ss' }}</td>
								<td [routerLink]="['/support', support._id]"><span class="mdi mdi-account-edit"></span></td>
							</tr>
						</tbody>
						<tbody *ngIf="supportdata.length == 0">
							<tr>
								<p>No records found</p>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="paginator d-flex flex-row">
					<button color="primary" (click)="p > 0?paginate('first'):null" [disabled]="p == 0"> << </button>
					<button (click)="p > 0?paginate('prev'):null" [disabled]="p == 0"> < </button>
					<button (click)="page > p?paginate('next'):null" [disabled]="page == p"> > </button>
					<button (click)="page > p?paginate('last'):null" [disabled]="page == p"> >> </button>
				</div>
			</div>
		</div>
	</div>
</div>