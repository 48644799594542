<div class="page-header">
	<h3 class="page-title">
		Change Pattern
	</h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="col-md-12 grid-margin">
					<div class="card">
						<div class="card-body">
							
					        <div class="d-flex justify-content-between">
					        	<div class="form-group text-center" style="margin-top: 24px;">
								<label for="name">Enter Old Pattern</label>
					            <div  id="patternLock" name="lock" class="pattern"  name="pattern" ></div>
					            <div *ngIf="msgPattern==0" class="errMsg">
					            	<span>Old Pattern Is Not Matched</span>
					            </div>
					        </div>
						        <div class="text-center" style="margin-top: 24px;">
									<label for="name">Enter New Pattern</label>
						            <div  id="patternLock1" name="lock1" class="pattern1"  name="pattern1"></div>
						        </div>
						        <div class="text-center" style="margin-top: 24px;">
									<label for="name">Confirm Pattern</label>
						            <div  id="patternLock2" name="lock2" class="pattern2"  name="pattern2"></div>
						            <div *ngIf="msgErr==0" class="errMsg">
						            	<span>Confirm Pattern Is Not-Matched</span>
						            </div>
						        </div>
					        </div>
					      	<div class="modal-footer text-center" [ngClass]="(checkPattern1 == false) || (match == false) ? 'disabled-text' : ''">
					        	<button type="submit" class="btn btn-info mx-auto btn-block btn-lg" (click)="submitFunc()" >Update</button>
					      	</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
