<div class="page-header">
	<h3 class="page-title">
	Game Details
	</h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="col-md-12 grid-margin">
					<div class="card">
						<div class="card-body" >
							<form class="forms-sample" #Managetrade="ngForm" autocomplete="off">
								<div >
									<div class="my-2 font-siz">
										<label class="col-3" for="network">User name:</label>
										<span class="">{{Det_name}}</span>
									</div>
								</div>
								<div class="my-2 font-siz">
									<label class="col-3" for="name">Game Id:</label>
									<span>{{Det_id}}</span>
								</div>
								<div class="my-2 font-siz">
									<label class="col-3" for="name">Bet Amount:</label>
									<span>{{Det_betamount}}</span>
								</div>
								<div class="my-2 font-siz">
									<label class="col-3" for="name">winning Amount:</label>
									<ng-container *ngIf="Det_status=='loser'">
									<span >- {{Det_pro_amt}}</span>
									</ng-container>
									<ng-container *ngIf="Det_status=='winner'">
									<span>{{Det_pro_amt}}</span>
									</ng-container>
								</div>
								<div class="my-2 font-siz">
									<label class="col-3" for="naye">Payout:</label>
									<span>{{Det_payout}}</span>
								</div>
								<div class="my-2 font-siz">
									<div class="d-flex">
										<label class="col-3" for="name">Result:</label>
										<div class="d-flex flex-column">
											<div class="d-flex pb-3">
												<!-- <label>last win</label> -->
											    <img alt="coin" [src]="(Det_result[Det_series-1].bet_result == 'heads')? '/assets/images/head_coin.png' : '/assets/images/tail_coin.png'" class="img-fluid headcoinss"  style="width: 50px" >
											</div>
											
											<div class="d-flex pb-3">
												<ng-container *ngFor="let coin of Det_result">
											<img class="idproof mr-1" style="width: 50px" [src]="(coin.bet_result == 'heads')? '/assets/images/head_coin.png' : '/assets/images/tail_coin.png'">
											</ng-container>
											</div>
											
										</div>
									</div>
									
								</div>
								<div class="my-2 font-siz">
									<label class="col-3" for="name">Status:</label>
									<span>{{Det_status}}</span>
								</div>
								<div class="my-2 font-siz">
									<label class="col-3" for="name">Client Seed: </label>
									<span>{{Det_clientSeed}}</span>
								</div>
								<div class="my-2 font-siz">
									<label class="col-3" for="name">serverSeed:</label>
									<span>{{Det_serverSeed}}</span>
								</div>
								<div class="my-2 font-siz">
									<label class="col-3" for="name">Nonce:</label>
									<span>{{Det_serverSeed}}</span>
								</div>
							</form>
							<hr>
							<div>
								<!-- <button type="submit" class="btn btn-gradient-primary">Cancel</button> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>