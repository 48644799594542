<div class="page-header">
  <h3 class="page-title">
    <span class="page-title-icon bg-gradient-info text-white mr-2">
      <i class="mdi mdi-view-dashboard"></i>                 
    </span>
    Dashboard
  </h3>
  <nav aria-label="breadcrumb">
    <ul class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">
        <span></span>Overview
        <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
      </li>
    </ul> 
  </nav>
</div>
<div class="row" *ngIf="isLoading">
  <div class="col-md-4 stretch-card grid-margin" routerLink="/users">
    <div class="card bg-gradient-info card-img-holder text-white">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>                  
        <h4 class="font-weight-normal mb-3">Total Users
          <i class="mdi mdi-diamond-outline mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">{{dashboard.userCount}}</h2>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" [routerLink]="['/withdraw']">
    <div class="card bg-gradient-danger card-img-holder text-white">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>                                    
        <h4 class="font-weight-normal mb-3">Number of Withdraw
          <i class="mdi mdi-bookmark mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">{{dashboard.totalWithdrawComplete}}</h2>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" [routerLink]="['/deposit']">
    <div class="card bg-gradient-success card-img-holder text-white">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>                                    
        <h4 class="font-weight-normal mb-3">Number of Deposit
          <i class="mdi mdi-chart-line mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">{{dashboard.totalDepositComplete}}</h2>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" routerLink="/currency">
    <div class="card bg-gradient-danger card-img-holder text-white">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>                                    
        <h4 class="font-weight-normal mb-3">Number of currency
          <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">{{dashboard.NumberOfCurrency}}</h2>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" [routerLink]="['/support']">
    <div class="card bg-gradient-success card-img-holder text-white">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>                                    
        <h4 class="font-weight-normal mb-3">Supports
          <i class="mdi mdi-diamond-outline mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">{{dashboard.NumberOfContact}}</h2>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" [routerLink]="['/game_history']">
    <div class="card bg-gradient-info card-img-holder text-white">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>                                    
        <h4 class="font-weight-normal mb-3">Number of Game's
          <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">{{dashboard.gameCount}}</h2>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" routerLink="/kyc/user">
    <div class="card bg-gradient-success card-img-holder text-white">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>                                    
        <h4 class="font-weight-normal mb-3">Kyc Status
          <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">{{dashboard.kycPending}}</h2>
      </div>
    </div>
  </div>
</div>
