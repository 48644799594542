<div class="page-header">
  <h3 class="page-title">
    <span class="page-title-icon bg-gradient-info text-white mr-2">
      <i class="mdi mdi-account-multiple"></i>                 
    </span>
    Users
  </h3>
</div>

<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-hover" *ngIf="isLoading">
						<thead>
							<tr>
								<th>S.No</th>
								<th>UserName</th>
								<th>Email</th>
								<th>Status</th>
								<th>TFA</th>
								<th>KYC</th>
								<th>Created at</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody *ngIf="users.length > 0">
							<tr *ngFor="let user of users; let i = index">
								<td>{{i+(p*limit)+1}}</td>
								<td>{{user.username}}</td>
								<td>{{user.email}}</td>
								<td class="text-capitalize cursor"><span [ngClass]="user.status == 0 ? 'danger-n' : 'success-n'">{{(user.status == 0) ? "Inactive" : "Active" }}</span></td>
								<td class="text-capitalize cursor"><span [ngClass]="user.tfa_status == 0 ? 'danger-n' : 'success-n'">{{(user.tfa_status == 0) ? "Deactive" : "Active" }}</span><span *ngIf="user.tfa_status == 1" class="mdi mdi-lock ml-2" (click)="tfaDisable(user.userId)"></span></td>

								<td class="text-capitalize cursor"><span [ngClass]="user.kyc_status == 2 ? 'danger-n' : user.kyc_status == 3 ? 'success-n' : 'gray-n'">{{user.kyc_status == 0 ? "Not verified" : user.kyc_status == 1 ? "Pending" : user.kyc_status == 2 ? "Rejected" : "Verifed"}}</span></td>
								<td>{{user.created_at | date:'dd-MM-yyyy hh:mm:ss' }}</td>
								<td><span class="mdi mdi-account-edit" [routerLink]="['/usersInfo', user.userId]"></span><span class="mdi mdi-lock-open-outline ml-2" *ngIf="user.status == 1" (click)="userAction(user.userId, 'lock')"></span><span class="mdi mdi-lock ml-2" *ngIf="user.status == 0" (click)="userAction(user.userId, 'open')"></span></td>
							</tr>	
						</tbody	>
						<tbody *ngIf="users.length == 0">
							<tr>
								<p>No records found</p>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="paginator d-flex flex-row">
					<button color="primary" (click)="p > 0?paginate('first'):null" [disabled]="p == 0"> << </button>
					<button (click)="p > 0?paginate('prev'):null" [disabled]="p == 0"> < </button>
					<button (click)="page > p?paginate('next'):null" [disabled]="page == p"> > </button>
					<button (click)="page > p?paginate('last'):null" [disabled]="page == p"> >> </button>
				</div>
			</div>
		</div>
	</div>
</div>