<div class="page-header">
	<h3 class="page-title">
		Withdraw Details
	</h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="form-group">
					<label for="name">Address :</label>&nbsp;
					<label class="cursor" (click)="copyText(withdrawData['address_info'], 'Address')">{{withdrawData.address_info}}  <i class="mdi mdi-animation menu-icon"></i></label>
				</div>	
				<div class="form-group">
					<label for="name">Currency :</label>&nbsp;
					<label >{{withdrawData.currency}}</label>
				</div>
				<div class="form-group">
					<label for="name">Amount :</label>&nbsp;
					<label>{{withdrawData.amount}}</label>
				</div>
				<div class="form-group">
					<label for="name">Transfer Amount :</label>&nbsp;
					<label >{{withdrawData.transfer_amount}}</label>
				</div>			
				<div class="form-group">
					<label for="name">Reference No :</label>&nbsp;
					<label class="cursor" (click)="copyText(withdrawData['reference_no'], 'Trasaction hash')">{{withdrawData.reference_no}}  <i class="mdi mdi-animation menu-icon"></i></label>
				</div>				
				<div class="form-group">
					<label for="name">Status :</label>&nbsp;
					<label class="text-capitalize">{{withdrawData.status}}</label>
				</div>					
				<div class="form-group">
					<label for="name">Payment Method :</label>&nbsp;
					<label class="text-capitalize">{{withdrawData.payment_method}}</label>
				</div>										
				<div class="form-group">
					<label for="name">Type :</label>&nbsp;
					<label class="text-capitalize">{{withdrawData.currency_type}}</label>
				</div>
				<div class="form-group">
					<label for="name">Created At :</label>&nbsp;
					<label>{{withdrawData.created_at | date:'dd-MM-yyyy hh:mm:ss'}}</label>
				</div>					
				<div class="form-group">
					<label for="name">Updated At :</label>&nbsp;
					<label>{{withdrawData.updated_at | date:'dd-MM-yyyy hh:mm:ss'}}</label>
				</div>
				<div class="form-group" *ngIf="withdrawData.receive_id ! == '' ">
					<label>receive_id</label>&nbsp;
					<label>{{withdrawData.receive_id}}</label>
				</div>

				<div *ngIf="withdrawData.status=='pending'"> 
				     <div  class="d-flex flex-md-row flex-column justiy-content-between m-2 ">
				        <div  class="w-50 label"> Action </div>
				        <div class="w-50">
				           <div class="ng-star-inserted" *ngIf="!Reject && !Approve">
				              <button type="button" class="btn btn-info mr-10" (click)="Approve = true"> Approve </button>
				              <button type="button" class="btn btn-danger ms-2" (click)="Reject = true"> Reject </button>
				           </div>
				           <form #cryptoDetails="ngForm" autocomplete="off">
				              <div class="card" *ngIf="Reject">
				                 <div class="card-header">
				                    <h5 class="card-title">REJECT FORM </h5>
				                 </div>
				                 <div  class="card-body">
				                    <div class="w-50 label"><b>Reason</b></div>
				                    <textarea class="form-control" [(ngModel)]="curr.reason" name="reason" placeholder="Please type reason here"></textarea><br/>
				                    <button class="btn btn-success me-2" (click)="executeAction(cryptoDetails,'reject')">SUBMIT</button>
				                    <button class="btn btn-danger" (click)="Reject = false">CANCEL</button>
				                 </div>
				              </div>
				              <div class="card" *ngIf="Approve">
				                 <div class="card-header">
				                    <h5 class="card-title">APPROVE  FORM </h5>
				                 </div>
				                 <div  class="card-body">
				                    <div>
				                       <span class="me-2">Withdraw Type :</span>
				                       <input name="options" id="css" type="radio" [value]=[internal]  [(ngModel)]="curr.options" (click)="checkdata('internal')">
				                       <label for="css" class="me-2">Internal</label>

				                       <input name="options1" id="html" type="radio" [value]=[external] [(ngModel)]="curr.options" (click)="checkdata('external')" >
				                       <label for="html">External</label>
				                    </div>
				                    <div *ngIf="internal || external">
				                       <div class="col-md-6" *ngIf="external">
				                          <label for="inputtrx" class="form-label">Transaction ID</label>
				                          <input type="text" class="form-control" [(ngModel)]="curr.trx" placeholder="Enter transaction ID" name="trxId" id="inputtrx">
				                       </div>
				                       <div class="col-md-6">
				                          <label for="inputotp" class="form-label">Enter OTP</label>
				                          <input type="number" class="form-control" placeholder="Enter 6 digit OTP" name="otp" id="inputotp" [(ngModel)]="curr.otp" >
				                       </div>
				                       <div class="text-primary" (click)="SendOtp()"><b>Send OTP</b></div>
				                       <button class="btn btn-success m-2" (click)="executeAction(cryptoDetails,'approve')">SUBMIT</button>
				                       <button class="btn btn-danger" (click)="Approve = false; external= false; internal= false;">CANCEL</button>
				                    </div>
				                 </div>
				              </div>
				           </form>
				        </div>
				     </div>
				  </div>
				<button type="button" class="btn btn-outline-primary btn-sm" [routerLink]="['/withdraw']">Back</button>
			</div>
		</div>
	</div>
</div>