import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ConnectionService } from '../connection.service';
import { AdminauthService } from '../services/adminauth.service';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'app-withdraw-info',
  templateUrl: './withdraw-info.component.html',
  styleUrls: ['./withdraw-info.component.scss']
})
export class WithdrawInfoComponent implements OnInit {
  token = localStorage.getItem('Key');
  TRN: any;
  isLoading = false;
  withdrawData:any = {};
  Reject:any= false; Approve:any = false;
  internal:any=false; external:any=false; curr:any={};  

  constructor(private dataService: ConnectionService, private router: Router, private conn: AdminauthService, private actRoute: ActivatedRoute, private notifier: NotifierService) { 
    if (!this.conn.isAuthenticated()) {
      this.notifier.notify('error', "Please login to continue");
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
    this.actRoute.params.subscribe((params) => {
      this.TRN = params['id'];
      let obj = { "CIdKey": this.TRN };
      this.dataService.postRequest('users/getWithdrawInfo', obj, this.token).subscribe((resData: any) => {
        if(resData) {
          if(resData.status == 1){
              this.withdrawData = resData.withdrawData;
          }else{
            this.notifier.notify('error', resData.msg);
          }
          this.isLoading = true;
        } else {
          this.notifier.notify('error', resData.msg);
        }
      });
    });
  }

  checkdata(type:any){
    if(type == 'internal'){
      this.internal = true; this.external = false;
    }else if(type == 'external'){
      this.internal = false; this.external = true;
    }
  }

  SendOtp(){
    let obj = {id: this.TRN};
    this.dataService.postRequest('users/sendOtp',obj,this.token).subscribe((resData:any)=>{
      if(resData.status!==1){
        this.notifier.notify('success', resData.message);
      }
    })
  }

  executeAction(form:NgForm,type:any){
    var data = form.value;
    if(type == 'approve'){
      if(this.internal){
        if(data.otp < 0){
          this.notifier.notify('error', 'Invalid otp'); return;
        };
        if(data.otp == undefined || data.otp == null){
          this.notifier.notify('error', 'Invalid otp details'); return;
        };
        if(data.otp.toString().length !== 6 ){
          this.notifier.notify('error', 'Invalid otp'); return;
        };
        let obj = {otp: data.otp, with_type: 'internal'};
        this.approveAction(obj);
      }else if(this.external){
        if(data.otp < 0){
          this.notifier.notify('error', 'Invalid otp'); return;
        };
        if(data.otp == undefined || data.otp == null || data.trxId == undefined || data.trxId == null){
          this.notifier.notify('error', 'Invalid details'); return;
        };
        if(data.otp.toString().length !== 6 ){
          this.notifier.notify('error', 'Invalid otp'); return;
        };
        let obj = {otp: data.otp, txnid: data.trxId, with_type: 'external'};
        this.approveAction(obj);
      }

    }else if(type == 'reject'){
      if(data.reason == undefined || data.reason == null){
        this.notifier.notify('error', 'Invalid Reason details'); return;
      };
      let obj = {reason: data.reason};
      this.rejectionAction(obj);
    }
  }

  rejectionAction(data:any){
    data._id = this.TRN;
    this.dataService.postRequest('users/rejectWithdraw',data,this.token).subscribe((resData:any)=>{
      if(resData.sucess == 1){
        this.notifier.notify('success', resData.message);
        this.router.navigateByUrl('/withdraw');
      }else{
        this.notifier.notify('error', resData.message);
      }
    })
  }

  approveAction(data:any){
    data._id = this.TRN;
    this.dataService.postRequest('users/approveWithdraw',data,this.token).subscribe((resData:any)=>{
      if(resData.sucess == 1){
        this.notifier.notify('success', resData.message);
        this.router.navigateByUrl('/withdraw');
      }else{
        this.notifier.notify('success', resData.message);
      }
    })
  }

  copyText(val: string, type:any) {
    let selBox = document.createElement('input');
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    window.scrollTo(0, 0);
    this.notifier.notify('success', type+" "+'copied to the clipboard');
    // this.alert.success('Address Copied to the clipboard', '', {timeOut: 2000});
  }
}
