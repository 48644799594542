import { Component, OnInit } from '@angular/core';
import { ConnectionService } from '../connection.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-user-balnce',
  templateUrl: './user-balnce.component.html',
  styleUrls: ['./user-balnce.component.scss']
})
export class UserBalnceComponent implements OnInit {
token = localStorage.getItem('Key');isLoading:any = true;
userId:any;currency:any; wallbal:any=[]; selectCurr:any= {};

  constructor(private conn: ConnectionService, private alert: ToastrService, private route: Router, private actRoute: ActivatedRoute) {
    this.actRoute.params.subscribe((params) => {
      this.userId = params['id'];
      this.currency = params['curr'];
      var obj={userId:this.userId}
      this.conn.postRequest('users/getUserBal', obj, this.token).subscribe((resData: any) => {
        if(resData.success==1){
          this.wallbal=resData.Baldata;
          var currBal = this.wallbal.filter(val => val.currency == this.currency);
          this.selectCurr.currency = currBal[0].currency;
          this.selectCurr.amount = currBal[0].amount;
          this.selectCurr.addAmt = '0';
        }else{
          this.alert.error(resData.msg,'')
        }
      })
    })
   }

  ngOnInit(): void {
  }

  getcurr(curr:any){
    this.currency = curr;
    var currBal = this.wallbal.filter(val => val.currency == this.currency);
    this.selectCurr.currency = currBal[0].currency;
    this.selectCurr.amount = currBal[0].amount;
    this.selectCurr.addAmt = '0';
  }

  submitForm(form:NgForm){
    var data = form.value;
    data.userId = this.userId;
    
    this.conn.postRequest('users/addBalance', data, this.token).subscribe((resData: any) => {
      if(resData.success==1){
        if(resData.success == 1){
          this.alert.success(resData.msg,'');
          var decodedValue = encodeURIComponent(this.userId);
          this.route.navigateByUrl('usersInfo/'+decodedValue);
        }else{
          this.alert.error(resData.msg,'');
        }
      }else{
        this.alert.error(resData.msg,'');
      }
    })

  }


}
