<div class="d-flex align-items-center auth px-0">
  <div class="row w-100 mx-0">
    <div class="col-lg-4 mx-auto">
      <div class="auth-form-light text-left py-5 px-4 px-sm-5">
        <div class="brand-logo">
          <img src="assets/images/logo-white.svg" alt="logo">
        </div>
        <form class="pt-3" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <input type="email" class="form-control form-control-lg" id="exampleInputEmail1" formControlName="email" name="email" placeholder="Email" [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required="">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.pattern">Email must be a valid email address</div>
            </div>
          </div>
          <div class="form-group" >
            <input type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" name="password" autocomplete="current-password" formControlName="password" [ngClass]="{ 'is-invalid': (f.password.touched || submitted) && f.password.errors }">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
          </div>  
          <div class="text-center">
            <div  id="patternLock" name="lock" class="pattern"  name="pattern" >
            </div>
        </div>        
          <div class="mt-3">
            <button type="submit" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">SIGN IN</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>  