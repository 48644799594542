<div class="page-header">
	<h3 class="page-title">
		Deposit Details
	</h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="form-group">
					<label for="name">Address : </label>&nbsp;
					<label class="cursor" (click)="copyText(depositData['address_info'], 'Address')">{{depositData.address_info}}  <i class="mdi mdi-animation menu-icon"></i></label>
				</div>
				<div class="form-group">
					<label for="name">Currency : </label>&nbsp;
					<label>{{depositData.currency}}</label>
				</div>
				<div class="form-group">
					<label for="name">Amount : </label>&nbsp;
					<label class="cursor">{{depositData.amount}}</label>
				</div>
				<div class="form-group">
					<label for="name">Fees : </label>&nbsp;
					<label >{{depositData.fees}}</label>
				</div>			
				<div class="form-group">
					<label for="name">Reference No : </label>&nbsp;
					<label class="cursor" (click)="copyText(depositData['reference_no'], 'Transaction hash')">{{depositData.reference_no}}  <i class="mdi mdi-animation menu-icon"></i></label>
				</div>				
				<div class="form-group">
					<label for="name">Status :</label>&nbsp;
					<label class="text-capitalize">{{depositData.status}}</label>
				</div>					
				<div class="form-group">
					<label for="name">Payment Method :</label>&nbsp;
					<label class="text-capitalize">{{depositData.payment_method}}</label>
				</div>										
				<div class="form-group">
					<label for="name">Type :</label>&nbsp;
					<label class="text-capitalize">{{depositData.currency_type}}</label>
				</div>
				<div class="form-group">
					<label for="name">Created At :</label>&nbsp;
					<label>{{depositData.created_at | date:'dd-MM-yyyy hh:mm:ss'}}</label>
				</div>					
				<div class="form-group">
					<label for="name">Updated At :</label>&nbsp;
					<label >{{depositData.updated_at | date:'dd-MM-yyyy hh:mm:ss'}}</label>
				</div>
				<button type="button" class="btn btn-outline-primary btn-sm" [routerLink]="['/deposit']">Back</button>
			</div>
		</div>
	</div>
</div>