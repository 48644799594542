<div class="page-header">
	<h3 class="page-title">
		Mines Game Details
	</h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="col-md-12 grid-margin">
					<div class="card">
						<div class="card-body" >
							<form class="forms-sample" #Managetrade="ngForm" autocomplete="off">
			                  	<div>
			                     	<div class="my-2 font-siz">
			                     		<label for="usrname" class="col-3">User name :</label>
			                     		<span>{{Det_name}}</span>
			                     	</div>
			                  	</div>
								<div class="my-2 font-siz">
									<label for="id" class="col-3">Game Id :</label>
			                     	<span>{{Det_id}}</span>
								</div>

								<div class="my-2 font-siz">
									<label for="betamt" class="col-3">Bet Amount :</label>
			                    	<span>{{Det_betamount}}</span>

								</div>

								<div class="my-2 font-siz">
									<label for="betamt" class="col-3">Winning Amount :</label>
			                    	<span>{{Det_betamount}}</span>

								</div>

								<div class="my-2 font-siz">
									<label for="betamt" class="col-3">Selected Mines :</label>
			                    	<span>{{Det_mines}}</span>

								</div>

								<div class="my-2 font-siz">
									<label for="apyout" class="col-3">Payout :</label>
			                    	<span>{{Det_payout}}</span>
								</div>

								<div class="my-2 font-siz">
									<label for="apyout" class="col-3">Result :</label>
			                    	<div class="items d-flex justify-content-between">
					                  	<div class="gridboxmini">
					                     	<ng-container *ngFor="let tile of tiles;">
					                        	<div class="diamondmini" id="{{tile}}res"></div>
					                      	</ng-container>
					                  	</div>
					               	</div>
								</div>

								<div class="my-2 font-siz">
									<label for="cliseed" class="col-3">Status :</label>
									<span>{{Det_status}}</span>
								</div>
								

								<div class="my-2 font-siz">
									<label for="cliseed" class="col-3">Client Seed :</label>
									<span>{{Det_clientSeed}}</span>
								</div>

								<div class="my-2 font-siz">
									<label for="serseed" class="col-3">serverSeed :</label>
			                    	<span>{{Det_serverSeed}}</span>
								</div>
								<div class="my-2 font-siz">
									<label for="nounce" class="col-3">Nonce :</label>
			                    	<span>{{Det_nounce}}</span>
								</div>
								
								<!-- <div class="form-group">
									<label for="name">DW RPC</label>
								</div>
								<div class="form-group">
									<label for="name">Network API</label>
								</div> -->
							</form>
							<hr>
					      <div>
					        <!-- <button type="submit" class="btn btn-gradient-primary">Cancel</button> -->
					      </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
