<div class="page-header">
	<h3 class="page-title">
		Game Details
	</h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="col-md-12 grid-margin">
					<div class="card">
						<div class="card-body" >
							<form class="forms-sample" #Managetrade="ngForm" autocomplete="off">
			                  	<div class="row">
			                     	<div class="col-md-7 mb-2">
			                     		<label for="network">User name</label>
			                     		<span>{{Det_name}}</span>
			                     	</div>
			                  	</div>
								<div class="form-group">
									<label for="name">Game Id</label>
			                     	<span>{{Det_id}}</span>
								</div>

								<div class="form-group">
									<label for="name">Client Seed </label>
									<span>{{Det_clientSeed}}</span>
								</div>

								<div class="form-group">
									<label for="name">serverSeed</label>
			                    	<span>{{Det_serverSeed}}</span>
								</div>
								<div class="form-group">
									<label for="name">Nonce</label>
			                    	<span>{{Det_serverSeed}}</span>
								</div>
								<div class="form-group">
									<label for="name">Bet Amount</label>
			                    	<span>{{Det_betamount}}</span>

								</div>
								<div class="form-group">
									<label for="name">Payout</label>
			                    	<span>{{Det_payout}}</span>
								</div>
								<!-- <div class="form-group">
									<label for="name">DW RPC</label>
								</div>
								<div class="form-group">
									<label for="name">Network API</label>
								</div> -->
							</form>
							<hr>
					      <div>
					        <!-- <button type="submit" class="btn btn-gradient-primary">Cancel</button> -->
					      </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
