<div class="page-header">
	<h3 class="page-title">
		Change Password
	</h3>
</div>
<div class="row">
	<div class="col-lg-12 grid-margin stretch-card">
		<div class="card">
			<div class="card-body">
				<div class="col-md-12 grid-margin">
					<div class="card">
						<div class="card-body">
							<form class="forms-sample" #changePassword="ngForm" autocomplete="off" novalidate>
								<div class="form-group">
									<label for="name">Old Password</label>
									<input type="password" class="form-control pwd-box" name="oldPassword" placeholder="Enter Old Password" [(ngModel)]="tradeData.oldpass" #oldPassword="ngModel" [ngClass]="{ 'is-invalid': (oldPassword.touched && oldPassword.invalid) }" (ngModelChange)="oldPass($event)" required>

					                <div *ngIf="oldPassword.errors && (oldPassword.valid || oldPassword.touched || changePassword.submitted)" class="errMsg">
					                   <span *ngIf="oldPassword.errors.required">{{"Enter Old Password"}}</span>
					                </div>
				                  	<div *ngIf="((!oldpassmatch) && oldPassword.valid)" class="errMsg">
				                    	<span *ngIf="!oldpassmatch">{{"Wrong password"}}</span>				            
				                	</div>
								</div>

								<div class="form-group">
									<label for="name">New Password</label>
									<input type="password" class="form-control" name="newPassword" placeholder="Enter new Password" [(ngModel)]="tradeData.newpass" #newPassword="ngModel" [ngClass]="{ 'is-invalid': (newPassword.touched && newPassword.invalid) }" (ngModelChange)="typePwd($event)"  required>
					                <div *ngIf="newPassword.errors && (newPassword.valid || newPassword.touched || changePassword.submitted)" class="errMsg">
					                   <span *ngIf="newPassword.errors.required">{{"Enter New Password"}}</span>
					                </div>
				                  	<div *ngIf="((!lower||!upper||!len||!num||!spec||newpassmatch) && newPassword.valid)" class="errMsg">
				                    	<span *ngIf="!lower">{{"Enter atleast one lowercase"}}</span>
				                    	<span *ngIf="!upper">{{"Enter atleast one uppercase"}}</span>
				                    	<span *ngIf="!len">{{"Enter atleast 8 characters"}}</span>
				                    	<span *ngIf="!num">{{"Enter atleast one number"}}</span>
				                    	<span *ngIf="!spec">{{"Enter atleast one special character"}}</span>
				                    	<span *ngIf="newpassmatch">{{"Do not match old password"}}</span>
				                  	</div>
								</div>
								<div class="form-group">
									<label for="name">Confirm Password</label>
									<input type="password" class="form-control" name="confirmPassword" placeholder="Please Enter Confirm Password" [(ngModel)]="tradeData.confirmpass" #confirmPassword="ngModel" [ngClass]="{ 'is-invalid': (confirmPassword.touched && confirmPassword.invalid) }" (ngModelChange)="ConfirmPass($event)" required>

					                <div *ngIf="confirmPassword.errors && (confirmPassword.valid || confirmPassword.touched || changePassword.submitted)" class="errMsg">
					                   <span *ngIf="confirmPassword.errors.required">{{"Enter confirm Password"}}</span>
					                </div>
				                  	<div *ngIf="((!errconpass) && confirmPassword.valid)" class="errMsg">
				                    	<span *ngIf="!errconpass">{{"Password does not match"}}</span>
				                  	</div>
								</div>
								<!-- <div class="form-group">
									<label for="name">Enter Old Pattern</label>
						            <div  id="patternLock" name="lock" class="pattern"  name="pattern" ></div>
						            <div *ngIf="msgPattern==0" class="errMsg">
						            	<span>Old Pattern Is Not Matched</span>
						            </div>
						        </div>
						        <div class="d-flex justify-content-between">
							        <div class="text-center" style="margin-top: 24px;">
										<label for="name">Enter New Pattern</label>
							            <div  id="patternLock1" name="lock1" class="pattern1"  name="pattern1"></div>
							        </div>
							        <div class="text-center" style="margin-top: 24px;">
										<label for="name">Confirm Pattern</label>
							            <div  id="patternLock2" name="lock2" class="pattern2"  name="pattern2"></div>
							            <div *ngIf="msgErr==0" class="errMsg">
							            	<span>Confirm Pattern Is Not-Matched</span>
							            </div>
							        </div>
						        </div> -->
							</form>

					      	<div class="modal-footer text-center">
					        	<button type="submit" class="btn btn-info mx-auto btn-block btn-lg" [disabled]="changePassword.invalid||!lower||!upper||!len||!num||!spec||!oldpassmatch||newpassmatch|| !errconpass" (click)="submitFunc(changePassword)" >Update</button>
					      	</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
